import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToogle';
import Header from '../Header';
import BackToTop from '../Helper/BackToTop';
import Footer from '../Footer';
import winner from '../../assets/images/quiz/winners-1.png';
import NewSchoolLogo from '../../assets/images/quiz/NewSchool.jpg';
import Edge from '../../assets/images/quiz/logo-removebg-preview.png';
import winner2 from '../../assets/images/quiz/winners-2.png';
import winner3 from '../../assets/images/quiz/winners-3.png';
import winner4 from '../../assets/images/quiz/winners-4.png';
import { Link } from 'react-router-dom'
import './Quiz.scss'
import { useNavigate } from 'react-router-dom';
import CompWait from '../Helper/compwait';
import useApi from '../../Hooks/useApi';

export default function Quiz({ user }) {
  // get url params
  const urlParams = new URLSearchParams(window.location.search);
  const srcc = urlParams.get('srcc');
  const { loading, data, error } = useApi(`${process.env.REACT_APP_SERVER}/api/competition-affiliates`);
  const schoolList = loading ? [] : data.data;

  const org = !loading && srcc && schoolList[srcc - 1]?.attributes;
  const navigate = useNavigate();
  const wait = CompWait();
  const [drawer, drawerAction] = useToggle(false);
  const [compUser, setCompUser] = useState();
  const [message, setMessage] = useState('');
  const route = () => {
    if (wait >= 36000) {
      if (!compUser) {
        navigate(`./register?${org ? `srcc=${org.Organisation}` : ''}`);
      } else {
        alert("You have already registered for the competition");
      }
    } else if (wait >= 1200 && wait <= 36000) {
      alert("Please wait till 11:30 am");
    } else if (wait < 1200 && wait >= -1200) {
      navigate('./waiting');
    } else {
      alert("Competition has ended");
    }
  }
  useEffect(() => {
    // get user from local storage
    const user1 = JSON.parse(localStorage.getItem('comp7User'));
    setCompUser(user1);
    if (wait >= 36000) {
      if (!user1) {
        setMessage('Register now');
      } else {
        setMessage('Registered');
      }
    } else if (wait >= 1200 && wait <= 36000) {
      if (!user1) {
        setMessage('Registeration Closed');
      } else {
        setMessage('Registered');
      }
    } else if (wait < 1200 && wait >= -1200) {
      setMessage('Participate Now');
    } else {
      setMessage('Competition Ended');
    }
  }, []);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user} />
      <div className="quiz">
        <div className="container">
          <div className="content">
            <div>
              <h2 className='main-head'>Online Grammar Competition</h2>
              <p> QWACK EDUCATION will organize a Grammar Competition for kids on regular intervals to boost their Grammar skills.</p>
              {/* <p>The competition will test age relevant English Grammar skills and leader board will be shown after completion of the Competition.</p> */}
            </div>
            <div className="dates">
              <div className="box">
                <div className="title-1">Date</div>
                <div className="details">
                  <h2>09<sup>th</sup></h2>
                  <p> June, 2024 </p>
                  {/* <h2>Coming Soon</h2> */}
                </div>
              </div>
              <div className="box">
                <div className="title-1">Time</div>
                <div className="details">
                  <h2>11:30 am</h2>
                  <p> 30 min test </p>
                  {/* <h2>Coming Soon</h2> */}
                </div>
              </div>
            </div>
            <div className="butts">

              <div className="button-6"
                onClick={() => route()}
              >
                {message ? message : 'Register Now'}
              </div>
              <a href="https://worksheets.qwackedu.com/Rules.pdf" target='_blank'>
                <div className="button-6">
                  Rules
                </div>
              </a>
            </div>
          </div>

          <div className="image">
            {org ?
              <>
                <p> Partnered with</p>
                <h1>{org.Organisation}</h1>
                <img src={org.link} alt="placeholder" />
              </>
              :
              <img src={schoolList.includes(srcc) ? srcc === "NewSchool" ? NewSchoolLogo : winner : winner} alt="placeholder" />
            }
          </div>
        </div>
        <div className="container">
          <div className="content">
            <h2>How to participate?</h2>
            <p>Students can register by filling their Details on our website's Competition Registration form.
            </p>
          </div>
          <div className="image">
            <img src={winner2} alt="placeholder" />
          </div></div>
        <div className="container">
          <div className="content">
            <h2>Winner selection criteria</h2>
            <p style={{ color: "#fff" }}>Winners will be selected using the following criteria</p>
            <ul>
              <li>Numbers of correct answers.</li>
              <li>Time taken for answering each question of the competition.</li>
              <li>The top 3 Participants will be choosen for Prizes</li>
            </ul>
          </div>
          <div className="image">
            <img src={winner4} alt="placeholder" />
          </div></div>
        <div className="container">
          <div className="content">
            <h2>Prizes For winners</h2>
            <p style={{ color: "#fff" }}>For 2nd-5th Standard</p>
            <ul>
              <li>
                Telescope
              </li>
              <li>
                Mapology
              </li>
              <li>
                Hover ball
              </li>
            </ul>
            <p style={{ color: "#fff" }}>For 6th-8th Standard</p>
            <ul>
              <li>
                Bowling Kit
              </li>
              <li>
                Sling puck
              </li>
              <li>
                Globe
              </li>
            </ul>
          </div>
          <div className="image">
            <img src={winner3} alt="placeholder" />
          </div></div>
      </div>
      {/* <Footer /> */}
      <BackToTop />
    </>
  )
}
