import React from 'react'
import "./CompPapers.scss"
import { Link } from 'react-router-dom'
import useApi from '../../Hooks/useApi'
import Loader from '../Helper/Loader'
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToogle';
import Header from '../Header';

export default function CompPapers({ user }) {
    // remove first 2 characters function
    const removeFirstTwo = (str) => {
        return str.slice(2);
    }
    const { data, loading, error } = useApi(`${process.env.REACT_APP_SERVER}/api/comp-questions?filters[isCompleted][$eq]=true`);
    // ?filters[isCompleted][$eq]=true
    const papers = loading ? [] : data.data || [];
    // console.log(papers);
    // get an array of all the dates and remove duplicates
    const dates = papers ? [...new Set(papers.map(paper => paper.attributes.date))] : [];
    console.log(dates);
    // convert yyyymmdd to date
    const date1 = (date) => {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);
        return `${day}/${month}/${year}`;
    }
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (
                    <>
                        <Drawer drawer={drawer} action={drawerAction.toggle} />
                        <Header action={drawerAction.toggle} user={user} />
                        <div className='compPapers'>
                            <div className="Heading">
                                <h1>Competition Papers</h1>
                            </div>
                            <div className="cardsla">
                                {
                                    dates.map((date, index) => (
                                        <div className="cardla" key={index}>
                                            <div className="title">
                                                <h2>{
                                                `Competition ${index + 1}`}</h2>
                                                held on {date1(date+"")}
                                            </div>
                                            <div className="standard">
                                                <h3>Standard</h3>
                                                {
                                                    papers.filter(paper => paper.attributes.date === date).map((paper, index1) => (
                                                            <Link to={`./../papers/1/${paper.id}`}>
                                                        <div className="paper" key={index1}>
                                                                <h4>{parseInt((paper.attributes.standard).slice(2,3))}</h4>
                                                        </div>
                                                            </Link>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}
