import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { simease } from "./simease.js";
import { simhard } from "./simhard.js";
import { antease } from "./antease.js";
import { anthard } from "./anthard.js";
import { useNavigate } from "react-router-dom";
// import useApi from '../../Hooks/useApi';
import useSound from "use-sound";
import './dropsingle.scss';
import playback from '../../assets/audio/vocabdropit.mp3';
// import uuid from "uuid/v4";

function DropitSingle() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search)
    const lvl = queryParams.get("lvl")
    const src = queryParams.get("src")
    const difficulty = queryParams.get("difficulty")
    // const [loop,{stop}] = useSound(playback);

    useEffect(() => {
        window.onbeforeunload = function () {
            return "Dude, are you sure you want to leave? Think of the kittens!";
        }


    }, [])
    const [score, setScore] = useState(0)
    const [answered, setAnswered] = useState([])
    const synwords = difficulty === 'easy' ? simease[0] : difficulty === 'hard' && simhard[0]
    const antwords = difficulty === 'easy' ? antease[0] : difficulty === 'hard' && anthard[0]

    const synsyn = difficulty === 'easy' ? simease[1] : difficulty === 'hard' && simhard[1]
    const antant = difficulty === 'easy' ? antease[1] : difficulty === 'hard' && anthard[1]
    const columnsFromBackend = src === 'a' ? antant[lvl - 1] : synsyn[lvl - 1];

    const onDragEnd = (result, columns, answers, SetAnswers, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            var sourceColumn = [];
            if (source.droppableId === "opt") {
                sourceColumn = answers[source.droppableId];
            } else {
                sourceColumn = columns[source.droppableId];
            }
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            if (destItems.length >= 1) {
                return;
            }
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setAnswered((prev) => [...prev, destination.droppableId]);
            if (source.droppableId === "opt") {
                if (parseInt(destination.droppableId) === removed.pairId) {
                    setScore((prev) => prev + 1);
                }
                setColumns({
                    ...columns,
                    // [source.droppableId]: {
                    //     ...sourceColumn,
                    //     items: sourceItems
                    // },
                    [destination.droppableId]: {
                        ...destColumn,
                        items: destItems
                    }
                });
                // matchit.splice()
                SetAnswers({
                    ...answers,
                    [source.droppableId]: {
                        ...sourceColumn,
                        items: sourceItems
                    }
                    // [destination.droppableId]: {
                    //     ...destColumn,
                    //     items: destItems
                    // }
                });
            } else {
                return;
            }

        } else {
            return;
        }
    };

    // const queryParams = new URLSearchParams(window.location.search)
    // const i = queryParams.get("lvl")
    const [columns, setColumns] = useState(columnsFromBackend);
    // const { loading, data } = useApi(`${process.env.REACT_APP_SERVER}/api/dropits/${i}?populate=*`)
    const Options1 = src === 'a' ? antwords[lvl - 1] : synwords[lvl - 1];
    const optionsColumn = {
        opt: {
            name: "Options",
            items: Options1
        },
    }
    const [answers, SetAnswers] = useState(optionsColumn);

    return (
        <>


            <div className="dropitsingle">
                {/* <div className='sun'></div> */}

                <div className="title">
                    <h2>DROP {src === 'a' ? 'OPPOSITE WORDS' : 'SIMILAR WORDS'}</h2>
                </div>
                <div className="game-container">
                    <DragDropContext
                        onDragEnd={result => onDragEnd(result, columns, answers, SetAnswers, setColumns)}
                    >
                        {Object.entries(answers).map(([columnId, column], index) => {
                            return (
                                <div className="option-column" key={columnId}>
                                    <h2>Options</h2>
                                    <div>
                                        <Droppable droppableId="opt" direction="horizontal" key={columnId}>
                                            {(provided, snapshot) => {
                                                return (

                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="option-container"
                                                    >
                                                        {column.items.map((item, index) => {
                                                            return (
                                                                <Draggable
                                                                    key={item.id}
                                                                    draggableId={item.id.toString()}
                                                                    index={index}
                                                                // isDragDisabled={columnId === "que"}
                                                                >
                                                                    {(provided, snapshot) => {
                                                                        return (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className="draggable"
                                                                                style={{
                                                                                    backgroundColor: snapshot.isDragging
                                                                                        ? "#fdede5"
                                                                                        : "#fff",
                                                                                    ...provided.draggableProps.style
                                                                                }}
                                                                            >
                                                                                {item.word}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                );
                                            }}
                                        </Droppable>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="questions">
                            {Object.entries(columns).map(([columnId, column], index) => {
                                return (
                                    <div
                                        className="column-question"
                                        key={columnId}
                                    >
                                        <h3>{column.name}</h3>
                                        <div style={{ margin: 8 }}>
                                            <Droppable droppableId={columnId} key={columnId} isDropDisabled={answered.includes(columnId)}>

                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            className='blank-container'

                                                        >
                                                            {column.items.map((item, index) => {
                                                                return (
                                                                    <Draggable
                                                                        key={item.id}
                                                                        draggableId={item.id.toString()}
                                                                        index={index}
                                                                        isDragDisabled={true}
                                                                    >
                                                                        {(provided, snapshot) => {
                                                                            return (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className='draggable'
                                                                                    style={{
                                                                                        backgroundColor: snapshot.isDragging
                                                                                            ? "#fdede5"
                                                                                            : "#fff",
                                                                                        ...provided.draggableProps.style
                                                                                    }}
                                                                                >
                                                                                    {item.word}
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </Draggable>
                                                                );
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    );
                                                }}
                                            </Droppable>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <button className="sub-butt" onClick={() => {
                            alert(`you Scored ${score} out of 5`)
                            navigate(-1)
                        }}
                        >

                            Submit</button>
                    </DragDropContext>
                </div>
            </div>
        </>
    );
}

export default DropitSingle;
