import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
export default function Auth() {
    // const navigate = useNavigate();
    useEffect(() => {
        // get user from local storage
        const user = JSON.parse(localStorage.getItem('comp7User'));
        // redirect to home if already logged in
        if (user) {
            // navigate('/app/dashboard', { replace: true });
            alert("You are already registered");
            window.location.href = "/competition";
        }
    }, []);
    const [OTPdisalbed, setOTPdisalbed] = useState(false)
    const urlParams = new URLSearchParams(window.location.search);
    const srcc = urlParams.get('srcc');
    const [form, setForm] = useState({
        schoolName: srcc ? srcc : "",
    })
    const onchange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const [isChecked, setIsChecked] = useState(true)
    const otp = (e) => {
        console.log(form);
        if(form.phone.length === 10){
        axios.post(`${process.env.REACT_APP_SERVER}/api/comp/otp`, form)
            .then(res => {
                console.log(res);
                if (res.data.status === 200) {
                    alert("OTP sent on WhatsApp successfully")
                } else {
                    alert(res.data ? res.data.message ? res.data.message : "Something went wrong,olaease try again later" : "Something went wrong,olaease try again later")
                }
            })
        }else{
            alert("Enter a valid phone number")
        }
    }
    const onSubmit = () => {
        if (!isChecked) {
            alert("Please accept the terms and conditions");
            return;
        }
        if (!form.childsName) {
            alert("Please enter your childs name");
            return;
        }
        if (!form.standard) {
            alert("Please enter Standard");
            return;
        }
        if (!form.schoolName) {
            alert("Please enter school name");
            return;
        }
        if (!form.otp) {
            alert("Please enter OTP");
            return;
        }
        axios.post(`${process.env.REACT_APP_SERVER}/api/comp/register`, {...form, CompId: 7})
        .then(res => {
            console.log(res);
            if (res.status === 200) {
                // store user data in local storage
                localStorage.setItem("comp7User", JSON.stringify(res.data.registeredUser));
                // navigate("/app/dashboard");
                alert("Registered successfully")
                const standard = form.standard;
                window.open(`https://worksheets.qwackedu.com/Rules${parseInt(standard.match(/\d+/)[0])}.pdf`, "_blank")
            } else {
                alert("Registration failed")
            }
        })
        .catch(err => {
            console.log(err.response.data.message);
            try {

                alert(err.response.data.message)
            } catch (error) {
                alert("Registration failed")
            }
        })
    }
    return (
        <>
            <div className="signup">
                <div className="signup-container">
                    <div className="signup-header">
                        <h1>Registration Form</h1>
                        <p>Register for the QWACK Grammar Competition</p>
                    </div>
                    <div className="signup-form">
                    <div className="phone">
                            <label htmlFor="Your Phone"><FontAwesomeIcon icon={faWhatsapp} />+91</label>
                            <input type="text" name="phone" id="phone1" placeholder='Enter your WhatsApp Number' onChange={(e) => onchange(e)} />
                        </div>
                        <input type="text" name="childsName" id="name2" placeholder="Enter your child's Name" onChange={(e) => onchange(e)} />
                        {/* <label htmlFor="standard">Standard:</label> */}
                        <select name="standard" id="standard" value={form.standard} onChange={(e) => onchange(e)}>
                            <option value="null">Select a Grade</option>
                            {/* <option value="-1st">1</option> */}
                            <option value="a-2nd">2</option>
                            <option value="a-3rd">3</option>
                            <option value="a-4th">4</option>
                            <option value="a-5th">5</option>
                            <option value="a-6th">6</option>
                            <option value="a-7th">7</option>
                            <option value="a-8th">8</option>
                        </select>
                        <input type="text" name="schoolName" id="school" placeholder="Enter your child's School's Name" onChange={(e) => !srcc && onchange(e)} value={form.schoolName} />
                        <button  className="btn-primary" disabled={OTPdisalbed} onClick = {(e) => otp(e)} >Get OTP</button>
                        
                        <input type="text" name="otp" id="otp" placeholder='Enter OTP' onChange={(e) => onchange(e)} />
                        <div>
                            <input type="checkbox" name="Privacy" id="privacy" checked={isChecked} onClick={() => { setIsChecked(!isChecked) }} readOnly />
                            <label htmlFor="Privacy" onClick={() => { setIsChecked(!isChecked) }} >I agree to the <Link to="/privacy">Privacy Policy</Link> and <Link to="/T&C">Terms of Use</Link></label>
                        </div>
                        <button  className="btn-primary" onClick={() => onSubmit()}>Register</button>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
