import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes as Switch,Navigate } from 'react-router-dom';
import Error from './components/Error';
import Member from './components/Member';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Home from './components/Home';
import Competition from './components/Competition';
import Vocabulary from './components/Vocabulary';
import WordOftheDay from './components/WordOftheDay';
import Playing from './components/Playing';
import Topics from './components/Topics'
import ScoreBoard from './components/ScoreBoard'
import Single from './components/SingleScore'
import Worksheets from './components/Worksheets';
import WorksheetSingle from './components/WorksheetSingle';
import VocabGames from './components/VocabGames';
import VocabScore from './components/VocabScore';
import WordCard from './components/WordCard';
import Dropit from './components/Dropit';
import DropitSingle from './components/DropitSingle';
import Quiz from './components/Quiz';
import Register from './components/Register';
import CompPaper from './components/CompPaper';
import CompPapers from './components/CompPapers';
import CompPlaying from './components/CompPlaying';
import Waiting from './components/Waiting';
import Participate from './components/Participate';
import Completed from './components/Completed';
import About from './components/About';
import Privacy from './components/Privacy';
import Tnc from './components/TnC'
import Ehard from './components/Ehard';
import Signup from './components/Signup';
import Login from './components/Login';
import Loginp from './components/Loginp';
import Otp from './components/Otp';
import playback from './assets/audio/vocabdropit.mp3';
import useSound from "use-sound";
import Standard from './components/Standard';

import './launch.scss';
import WhatsAppp from './components/WhatsApp';
function Routes() {
    const [loop,{stop}] = useSound(playback, { volume: 0.05, loop: true });
    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState(null);
    // useEffect(() => {
    //     // const user = localStorage.getItem('user');
    //     // if (user) {
    //     //     setUser(JSON.parse(user));
    //     // }
    //     // location.reload();
    //     // setLoading(false);
    // }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        window.localStorage.getItem('childsName') && setUser(JSON.parse(window.localStorage.getItem('childsName')));
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    const [players, setPlayers] = useState([{
        'id': 1,
        'name': "Darshan",
        'score': 80
    },
    {
        'id': 2,
        'name': "Reedham",
        'score': 900
    },
    {
        'id': 3,
        'name': "Janki",
        'score': 900
    },
    {
        'id': 4,
        'name': "Shiva",
        'score': 70
    }])
    // stop()
    return (
        <>
            {loading && (
                <div className={`loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}

                <div className={`visible ${loading === false ? 'active' : ''}`}>
                    <Router>
                        <ScrollToTop>
                            <Switch>
                                <Route path="/" element={<Home user={user} setUser={setUser} />} />
                                <Route path="/member" element={<Member user={user} />} />
                                {/* <Route path="/register" element={<Login />} /> */}
                                <Route path="/worksheets" element={<Worksheets user={user} />} />
                                <Route path="/worksheets/:name" element={<WorksheetSingle user={user} />} />
                                <Route path="/vocabulary" element={<Vocabulary user={user} />} />
                                <Route path="/vocabulary/word" element={<WordOftheDay user={user} />} />
                                <Route path="/vocabulary/games" element={<VocabGames user={user} />} />
                                <Route path="/vocabulary/games/ehard" element={<Ehard user={user} />} />
                                <Route path="/vocabulary/games/wordcard" element={<WordCard user={user} />} />
                                <Route path="/vocabulary/games/wordcard/score" element={<VocabScore user={user} />} />
                                <Route path="/vocabulary/games/dropit" element={<Dropit user={user} />} />
                                <Route path="/vocabulary/games/dropit/playing" element={<DropitSingle />} />
                                <Route path="/quiz" element={<Competition  user={user}/>} />
                                {/* <Route path="/competition/join" element={<Join players={players} setPlayers= {setPlayers}/>} /> 
                            <Route path="/competition/create" element={<Create players={players} setPlayers= {setPlayers}/>} />  */}
                                <Route path="/quiz/standard" element={<Standard />} />
                                <Route path="/quiz/topics" element={<Topics players={players} setPlayers={setPlayers} user={user} />} />
                                <Route path="/quiz/playing" element={<Playing players={players} setPlayers={setPlayers} />} />
                                {/* <Route path="/quiz/scores" element={<ScoreBoard players={players} setPlayers={setPlayers} />} /> */}
                                <Route path="/quiz/score-single" element={<Single players={players} setPlayers={setPlayers} user={user} />} />
                                {/* <Route path="/quiz/waiting" element={<Waitingroom players={players} setPlayers= {setPlayers}/>} /> */}
                                <Route path="/competition" element={<Quiz user={user} />} />
                                <Route path="/competition/register" element={<Register />} />
                                <Route path="/competition/papers" element={<CompPapers user={user}/>} />
                                <Route path="/competition/papers/:tag" element={<Navigate to="../competition/papers" replace />} />
                                <Route path="/competition/papers/:tag/:id" element={<CompPaper/>} />
                                <Route path="/competition/waiting" element={<Waiting />} />
                                <Route path="/competition/participate" element={<Participate />} />
                                <Route path="/competition/playing" element={<CompPlaying />} />
                                <Route path="/competition/completed" element={<Completed />} />
                                <Route path='/AboutUs' element={<About user={user} setUser={setUser} />} />
                                <Route path='/T&C' element={<Tnc user={user} setUser={setUser} />} />
                                <Route path='/privacy' element={<Privacy user={user} setUser={setUser} />} />
                                <Route path='/refund' element={<About user={user} setUser={setUser} />} />
                                {/* <Route path="/member" element={<Signup user={user} setUser={setUser} />} /> */}
                                <Route path="/signup" element={<Signup user={user} setUser={setUser} />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/loginp" element={<Loginp />} />
                                <Route path="/otp" element={<Otp />} />
                                <Route path="/whatsapp" element={<WhatsAppp/>} />
                                <Route path="/error" element={<Error />} />
                                <Route path="*" element={<Error />} />
                            </Switch>
                        </ScrollToTop>
                    </Router>
                </div>
            
        </>
    );
}

export default Routes;