import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import home logo font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
export default function Auth({ user, setUser }) {
    const navigate = useNavigate();
    // form validation
    useEffect(() => {
        if (window.localStorage.getItem('childsName')) {
            navigate('/')
        // }else {
        //     alert('Login with OTP is currently not available. Please use password to login.')
        //     navigate('/loginp')
        }
    }, [])
    const [form, setForm] = useState({
        phone: '',
    })
    const [otp, setOtp] = useState({
        otp: '',
        phone: '',
    })

    // onsubmit
    const onSubmit = (e) => {
        // check phone 
        const number = parseInt(form.phone);
        if (number && number >= 1000000000 && number <= 9999999999) {
            axios.post(`${process.env.REACT_APP_SERVER}/api/user/logino`, form)
                .then(res => {
                    if (res.data.message) {
                        alert(res.data.message);
                    }
                })
        } else {
            alert("Enter valid phone number")
        }
    }

    const onVerify = (e) => {
        // check phone
        const number = parseInt(otp.phone);
        if (number && number >= 1000000000 && number <= 9999999999) {
            
            axios.post(`${process.env.REACT_APP_SERVER}/api/user/vlogin`, { otp: otp.otp, phone: otp.phone })
            .then(res => {
                if (res.data.message === 'verified') {
                    window.localStorage.setItem('token', JSON.stringify(res.data.token));
                    window.localStorage.setItem('id', JSON.stringify(res.data.entry11111.id));
                    window.localStorage.setItem('phone', JSON.stringify(res.data.entry11111.phone));
                    window.localStorage.setItem('username', JSON.stringify(res.data.entry11111.username));
                    window.localStorage.setItem('childsName', JSON.stringify(res.data.entry11111.childsName));
                    window.localStorage.setItem('standard', JSON.stringify(res.data.entry11111.standard));
                    window.localStorage.setItem('role', JSON.stringify(res.data.entry11111.role));
                    navigate('/')
                    alert(res.data.message);
                    // reload
                    window.location.reload();
                    setUser(res.data.entry11111.childsName);
                } else {
                    alert(res.data.message)
                    console.log(res.data.message);
                }
                
            }).catch(err => {
                console.log(err);
            })
        } else {
            alert("Enter valid phone number")
            return;
        }
    }
    onchange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        setOtp({
            ...otp,
            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <div className="signup">
                {/* home */}
                <div className="home">
                    <Link to="../">
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Link>
                </div>
                <div className="signup-container">
                    <div className="signup-header">
                        <h1>Log-in</h1>
                        <p>Log into your account</p>
                    </div>
                    <div className="signup-form">
                        <div className="phone">
                            <label htmlFor="Your Phone">+91</label>
                            <input type="text" name="phone" id="phone1" placeholder='Enter your Phone' onChange={(e) => onchange(e)} />
                        </div>
                        {/* <button>Get OTP on Whatsapp <i className='fa fa-whatsapp'></i></button> */}

                        <button onClick={() => onSubmit()} className="btn-primary" >Get OTP</button>
                        <input type="text" name="otp" id="otp" placeholder='Enter OTP' onChange={(e) => onchange(e)} />
                        <button onClick={(e) => onVerify(e)} className="btn-primary">Login</button>
                        <div className="form-footer">
                            <p>Don't have an account? <Link to='/signup'>Sign Up</Link></p>
                            <p><Link to="/loginp" >login with password</Link></p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
