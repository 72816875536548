import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import './VocabGames.scss'
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';
import ModalVideo from 'react-modal-video'

export default function VocabGames({ user }) {
    const [isOpen, setOpen] = useState(false)
    const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user} />
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="Gu4KZ44kL54" onClose={() => setOpen(false)} />
      <div className='VocabGames'>
        <div className='wave wave1'></div>
        <div className='wave wave2'></div>
        <div className='wave wave3'></div>
        <div className='sun'></div>
        <div className="gamecard">
          <div className="head">

            <h2>Fun Games</h2>
          </div>
          <div className='htp'>
            <p onClick={()=> setOpen(true)}>How to play <i class="fa fa-question-circle" aria-hidden="true"></i></p>
          </div>
          <Link to='../../vocabulary/games/ehard?src=s&game=wordcard'>
            <div className='games'>
              <h1>Flip the Card - Similar Words</h1>
            </div>
          </Link>
          <Link to='../../vocabulary/games/ehard?src=a&game=wordcard'>
            <div className='games'>
              <h1>Flip the Card - Opposite Words</h1>
            </div>
          </Link>
          <Link to='../../vocabulary/games/ehard?src=s&game=dropit'>
            <div className='games'>
              <h1>Match Similar Words</h1>
            </div>
          </Link>
          <Link to='../../vocabulary/games/ehard?src=a&game=dropit'>
            <div className='games'>
              <h1>Match Opposite Words</h1>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  )
}
