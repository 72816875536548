import React, {useState} from 'react';
// import heroThumbOne from '../../assets/images/hero-thumb-1.png';
import heroThumbTwo from '../../assets/images/mickey-9406.png';
import bubble from '../../assets/images/shape/bubble.png'
import './Hero.scss'
import useSound from 'use-sound';
import pop from '../../assets/audio/pop.mp3';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
function Hero({ className , user, setUser}) {
    const [isOpen, setOpen] = useState(false)
    // console.log(window.localStorage.getItem('role'));    
    const onClick = async (e) => {
        e.target.style.display = "none"
        play()
        setTimeout(function () {
            e.target.style.display = "block"
        }, (3 * 1000));
        // play
    }
    const [play] = useSound(pop);
    return (
        <>
            <section className={`hero-area ${className || ''}`}>
                <div className="container">
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="rgXnO2PW9Cw" onClose={() => setOpen(false)} />
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="hero-content">
                                <span className="con1 slide">
                                    <h3>
                                        {/* <div className='welc'>
                                        Grammar ki पाठशाला
                                        </div> */}
                                        <div className="con2 ">
                                            <span className="q">Q</span>
                                            <span className="w">W</span>
                                            <span className="a">A</span>
                                            <span className="c">C</span>
                                            <span className="k">K</span>
                                        </div>
                                    </h3>
                                </span>
                                <h1 className="title ">
                                    <Link to="/quiz">
                                        <span className='alpha'>Quizzes</span>
                                    </Link>
                                    <br />
                                    <Link to="/worksheets">
                                        <span className='beta'>Worksheets</span>
                                    </Link>
                                    <br />
                                    <span className='gama'>And</span>
                                    <br />
                                    <Link to="/competition">
                                        <span className='delta'>Competition</span>
                                    </Link>
                                    <br />
                                    <span className='mue'>Kit</span>
                                    <br />
                                </h1>
                                {/* <p>
                                   Grammar ki पाठशाला
                                </p> */}
                                <ul>
                                    <li>
                                        <a href='#' onClick={()=> setOpen(true)}><div className="signupbutton"><i className="fa fa-eye" /> Watch demo </div>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="/about">
                                            <i className="fa fa-info-circle" /> Learn more
                                        </a>
                                    </li> */}
                                    <li>
                                        {
                                            user ?
                                            window.localStorage.getItem('role') &&
                                                window.localStorage.getItem('role') === '"user"' &&
                                                <a href={`https://pages.razorpay.com/pl_KQ2olF30knVH2q/view?phone=${window.localStorage.getItem('phone') && window.localStorage.getItem('phone').replaceAll('"', '')}`}>
                                                <div className="signupbutton"> <i className="fa fa-sign-in-alt" /> Become a member</div>
                                                </a>
                                        :
                                        <Link to="/signup">
                                        <div className="signupbutton"><i className="fa fa-sign-in-alt" />Sign up for free</div>
                                        </Link> 
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbTwo} alt="" />
                                    {/* <img src={heroThumbOne} alt="" /> */}
                                </div>
                                {/* <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                <div className="hero-shape-2" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                {/* <div className="hero-shape-3" onClick={(e) =>onClick(e)}>
                        <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div> */}
                <div className="hero-shape-4" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                <div className="hero-shape-5" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                <div className="hero-shape-6" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                <div className="hero-shape-7" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                <div className="hero-shape-8" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
                <div className="hero-shape-9" onClick={(e) => onClick(e)}>
                    <img className="pop" src={bubble} height="100" alt='' width="100" />
                </div>
            </section>
        </>
    );
}

export default Hero;