import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

export default function WhatsAppp() {
  const navigate = useNavigate();
    useEffect(() => {
      navigate('../')
    }, [])
  return (
    <div>Redirecting to WhatsAppp</div>
  )
}
