import Routes from './Routes';
import { useEffect, useState } from 'react';
import Maintenance from './components/Maintenance';
import useApi from './Hooks/useApi';
import Loader from './components/Loading';
import axios from 'axios';

function App() {
    const link = `${process.env.REACT_APP_SERVER}/api/maintenance`;
    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get('src');
    console.log(src);
    if(src) {
        console.log("ho ja");
        window.localStorage.setItem('src',(src));
    }
    const { loading, data, error } = useApi(link)
    const switch1 = loading ? null : error ? null : data.data.attributes.Switch;
    // post request with id and handle response and change local storage
    useEffect(() => {
        // check if id is present in local storage
        if (!window.localStorage.getItem('id')) {
            return;
        }
        axios.post(`${process.env.REACT_APP_SERVER}/api/user/validate`, { id: JSON.parse(window.localStorage.getItem('id')) })
        .then(user => {
            window.localStorage.setItem('id', JSON.stringify(user.data.id));
            window.localStorage.setItem('phone', JSON.stringify(user.data.phone));
            window.localStorage.setItem('username', JSON.stringify(user.data.username));
            window.localStorage.setItem('childsName', JSON.stringify(user.data.childsName));
            window.localStorage.setItem('standard', JSON.stringify(user.data.standard));
            window.localStorage.setItem('role', JSON.stringify(user.data.role));
        })
    }, [])
    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (
                    <>
                        {
                            error ? (
                                <Maintenance />
                            ) : (
                                switch1 ? (
                                    <div >

                                        <Maintenance />
                                    </div>
                                ) : (
                                    <>
                                        <Routes />
                                    </>
                                )
                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default App;