import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import './Vocabulary.scss'
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';

export default function Vocabulary({user}) {
  const [drawer, drawerAction] = useToggle(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const date_1 = new Date('7/4/2022');
  // const date_2 = new Date();

  // const days1 = (date_1, date_2) => {
  //   const difference = date_2.getTime() - date_1.getTime();
  //   const TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) - 1;
  //   return TotalDays;
  // }
  // const link = `${process.env.REACT_APP_SERVER}/api/words?pagination[start]=${0}&pagination[limit]=7`
  // const { loading, data } = useApi(link)
  // const temp = loading ? [] : data.data
  // const handleClick = (way) => {
  //   way === "left"
  //     ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : words.length - 1)
  //     : setCurrentSlide(currentSlide < words.length - 1 ? currentSlide + 1 : 0);
  // };
  // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // const dow = new Date().getDay();

  // const reverseArray = (input) => {
  //   var ret = [];
  //   for (var i = input.length - 1; i >= 0; i--) {
  //     ret.push(input[i]);
  //   }
  //   return ret;
  // }

  // const words = reverseArray(temp)

  return (
    <>
      <div className="Vocabulary">
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user} />
        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
        <div className="wave wave3"></div>
        <div className="sun"></div>
        <div className="flex">
          <div className="fg">
            <Link to="../vocabulary/word">
              <div className="games">
                <h1>Word of the Day</h1>
              </div>
            </Link>
          </div>
          <div className="fg">
            <Link to="../vocabulary/games">
              <div className="games">
                <h1>Play Fun games</h1>
              </div>
            </Link>
          </div>
          {/* <div className="fg">
            <Link to='../vocabulary/games'>
              <div className='games'><h1>Play Fun games</h1></div>
            </Link>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}
