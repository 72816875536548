import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToogle';
import BackToTop from '../Helper/BackToTop';
import Drawer from '../Mobile/Drawer';
import Footer from '../Footer';
import Hero from '../Hero';
import Header from '../Header';
import Project from '../Project';
import Services from '../Services';
import Modal from '../Modal';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Member from '../Member';
import micro from "../../assets/images/micro.png" ;
import "./Home.scss"

function Home({ user, setUser }) {
    const [drawer, drawerAction] = useToggle(false);
    const [verified, setVerified] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get('src');
    
    useEffect(() => {
        // get url
        const url = window.location.href;
        const urlArray = url.split('/');
        // check if url includes #veified
        setVerified(urlArray.includes('#verified'));
        // console.log(verified);
    }, [window.location.href])

    

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} user={user} setUser={setUser} />
            {!src && !(src === 'phoneapp') &&
            <Hero user={user} setUser={setUser} />
            }
            <Services />
            <div className="micro">
            <img src={micro} alt="" />
            </div>
            {/* <Project /> */}
            <Member />
            <Footer />
            <BackToTop />
        </>
    );
}

export default Home;