import React from 'react'
import { Link } from 'react-router-dom'
import './Dropit.scss'
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToogle';
import Header from '../Header';
import {simhard} from "../DropitSingle/simhard";
import {simease} from "../DropitSingle/simease.js";
import {antease} from "../DropitSingle/antease.js";
import {anthard} from "../DropitSingle/anthard.js";

export default function Dropit({ user  }) {
  const [drawer, drawerAction] = useToggle(false);
  const queryParams = new URLSearchParams(window.location.search)
  const src = queryParams.get("src")
  const difficulty = queryParams.get("difficulty")
  const arr = (src === 's' ? (difficulty === 'easy' ? simease[0] : difficulty === 'hard' && simhard[0]) : (difficulty === 'easy' ? antease[0] : difficulty === 'hard' && anthard[0])).length;
  const lvls = [...Array(arr)]
  
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user}/>
      <div className="dropit">
        <h2 className='title'> Drop {src === 's' ? "Synonym" : 'Antonym'}</h2>
        <p>Select a level to start playing</p>
        <div className="butts">
          {lvls.map((e,i) => (
            <Link to={`playing?src=${src}&lvl=${i+1}&difficulty=${difficulty}`} >
              <div className="choice" >
                {i + 1}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}