import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
export default function Auth({ user, setUser }) {
    const navigate = useNavigate();
    // form validation
    const [form, setForm] = useState({
        phone: '',
    })
    const [otp, setOtp] = useState({
        otp: '',
        phone: '',
    })

    useEffect(() => {
        if (window.localStorage.getItem('childsName')) {
            navigate('/')
        // }else {
        //     alert('Login with OTP is currently not available. Please use password to login.')
        //     navigate('/loginp')
        }
    }, [])
    // onsubmit
    const [password1, setPassword1] = useState('');
    const onSubmit = (e) => {
        // check phone 
        const number = parseInt(form.phone);
        if (number && number >= 1000000000 && number <= 9999999999) {
            axios.post(`${process.env.REACT_APP_SERVER}/api/user/loginp`, form)
            .then(res => {
                if (res.data.message === 'verified') {
                    window.localStorage.setItem('token', JSON.stringify(res.data.token));
                    window.localStorage.setItem('id', JSON.stringify(res.data.entry.id));
                    window.localStorage.setItem('phone', JSON.stringify(res.data.entry.phone));
                    window.localStorage.setItem('username', JSON.stringify(res.data.entry.username));
                    window.localStorage.setItem('childsName', JSON.stringify(res.data.entry.childsName));
                    window.localStorage.setItem('standard', JSON.stringify(res.data.entry.standard));
                    window.localStorage.setItem('role', JSON.stringify(res.data.entry.role));
                    navigate('/')
                    window.location.reload();
                } else {
                    alert(res.data.message)
                    console.log(res);
                }

            }).catch(err => {
                console.log(err);
            })
        } else {
            alert("Enter valid phone number")
        }
    }

    const onVerify = (e) => {
        axios.post(`${process.env.REACT_APP_SERVER}/api/user/vlogin`, { otp: otp.otp, phone: otp.phone })
            .then(res => {
                if (res.data.message === 'verified') {
                    window.localStorage.setItem('token', JSON.stringify(res.data.token));
                    window.localStorage.setItem('phone', JSON.stringify(res.data.entry11111.phone));
                    window.localStorage.setItem('username', JSON.stringify(res.data.entry11111.username));
                    window.localStorage.setItem('childsName', JSON.stringify(res.data.entry11111.childsName));
                    window.localStorage.setItem('standard', JSON.stringify(res.data.entry11111.standard));
                    window.localStorage.setItem('role', JSON.stringify(res.data.entry11111.role));
                    navigate('/')
                    setUser(res.data.entry11111.childsName);
                } else {
                    // alert(res.data.message)
                    console.log(res);
                }

            }).catch(err => {
                console.log(err);
            })
    }
    onchange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        setOtp({
            ...otp,
            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <div className="signup">
                <div className="signup-container">
                    <div className="signup-header">
                        <h1>Log-in</h1>
                        <p>Log into your account</p>
                    </div>
                    <div className="signup-form">
                        <div className="phone">
                            <label htmlFor="Your Phone">+91</label>
                            <input type="text" name="phone" id="phone1" placeholder='Enter your Phone' onChange={(e) => onchange(e)} />
                        </div>
                        {/* <button>Get OTP on Whatsapp <i className='fa fa-whatsapp'></i></button> */}
                        <div className="password">
                            <input type={password1 ? "password" : "text"} name="password" id="password1" placeholder='Enter your Password' onChange={(e) => onchange(e)} />
                            <i className={`fa ${!password1 ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setPassword1(!password1)}></i>
                        </div>
                        {/* <button onClick={() => onSubmit()} className="btn-primary" >Get OTP</button> */}
                        {/* <input type="text" name="otp" id="otp" placeholder='Enter Your Password' onChange={(e) => onchange(e)} /> */}
                        <button onClick={(e) => onSubmit(e)} className="btn-primary">Login</button>
                        <div className="form-footer">
                            <p>Don't have an account? <Link to='/signup'>Sign Up</Link></p>
                            <p><Link to="/login" >login with OTP</Link></p>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
