import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './Member.scss'

export default function Member() {
    const user = JSON.parse(localStorage.getItem('childsName'));
    const role = JSON.parse(localStorage.getItem('role'));
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState('');
    const [link, setLink] = useState(`https://pages.razorpay.com/pl_KQ2olF30knVH2q/view?phone=${localStorage.getItem('phone') && JSON.parse(localStorage.getItem('phone'))}`)
    useEffect(() => {
        if (role == "member" && window.location.href.endsWith("/member")) {
            navigate('/');
        }
    })
    return (
        <>
            <div className={`member ${role == "member" && "hideit"}`} id='member'>
                <div className="memwrapper">
                    <div className="title">
                        <h2>Membership</h2>
                    </div>
                    <div className="price">
                        <span className='og'>₹999/year</span>
                        <span>
                            ₹399/year
                        </span>

                    </div>
                    <div className="features">
                        <div className="feature">
                            <div className="icon">
                                <i className="fas fa-check"></i>
                            </div>
                            <div className="text1">
                                <h3>200+ Premium Printable <u> Grammar Worksheets</u> With separate answer key</h3>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="fas fa-check"></i>
                            </div>
                            <div className="text1">
                                <h3>1000+ Questions in <u>quizzes</u> with instant results and report</h3>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="fas fa-check"></i>
                            </div>
                            <div className="text1">
                                <h3>80+ <u>Fun Word Games</u> for Vocabulary practice and daily new words</h3>
                            </div>
                        </div>
                    </div>
                    <div className={`code ${!user && "hideit"}`}>
                        <input type="text" className='codeInput' name='coupon' onChange={(text) => setCoupon(text.target.value)} value={coupon} placeholder="Enter Coupon Code" />
                        <button className='codeButton'
                            onClick={() => {
                                if (coupon === "j bhi") {
                                    setLink(`https://pages.razorpay.com/pl_L9ZlWYfJpHHrus/view?phone=${localStorage.getItem('phone') && JSON.parse(localStorage.getItem('phone'))}`)
                                    alert("Coupon Applied " + coupon);
                                } else if ((coupon).toLowerCase() === "edgeover") {
                                    setLink(`https://pages.razorpay.com/pl_L9ZlWYfJpHHrus/view?phone=${localStorage.getItem('phone') && JSON.parse(localStorage.getItem('phone'))}`)
                                    alert("Coupon Applied " + coupon);

                                } else {
                                    setCoupon('');
                                    alert("Invalid Coupon");
                                }
                            }}>Apply</button>
                    </div>
                    <div className="buttonWrapper">
                        <div className="button">
                            <a href={user ? link : "/signup"}>Become a Member</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
