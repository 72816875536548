import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import './Ehard.scss'
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';

export default function Ehard({ user }) {
  const queryParams = new URLSearchParams(window.location.search)
    const src = queryParams.get("src")
    const game = queryParams.get("game")
  const [drawer, drawerAction] = useToggle(false);
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} user={user} />
      <div className='Ehard'>
        <div className='wave wave1'></div>
        <div className='wave wave2'></div>
        <div className='wave wave3'></div>
        <div className='sun'></div>
        <div className="flex">

          
          <div className="fg">
            <Link to={`../vocabulary/games/${game}?src=${src}&difficulty=easy`}>
              <div className='games'><h1>Easy</h1>
              <p>Suitable for Age : 7 to 8 years</p>
              </div>
            </Link>
          </div>
          <div className="fg">
            <Link to={`../vocabulary/games/${game}?src=${src}&difficulty=hard`}>
              <div className='games'><h1>Hard</h1>
              <p>Suitable for Age : 9 to 10 years</p>
              </div>
            </Link>
          </div>
          {}
        </div>
        

      </div>
      <Footer />
    </>
  )
}
