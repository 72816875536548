import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import CompWait from '../Helper/compwait'
import { useNavigate } from 'react-router-dom'
export default function Playing() {
    const navigate = useNavigate();
    const wait = CompWait();
    if (wait <= -1220) {
        alert('Competition has ended');
        navigate('/competition');
    }
    return (
        <>
            <div className='CompPlaying'>
                <div className="cont">

                    <div className="question-wrapper">
                        <div className="box">
                            <div className="question">
                                <div className="number bigg">🏆</div>
                                <h2>You have Completed the Competition successfully, The result will be sent to you via WhatsApp within 30 Days Of the Competition. For any doubts or queries please contact us on WhatsApp</h2>
                            </div>
                            <div className="answers">
                                <div className={`option`} onClick={() => {
                                    navigate('/competition')
                                }
                                }>
                                    <h3>Go Home</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
