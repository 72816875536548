import { useEffect, useState } from 'react';

// time and date to seconds
const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    return (+hours * 60 * 60) + (+minutes * 60) + (+seconds);
};
const dateToSeconds = (date) => {
    const [year, month, day] = date.split('-');
    return new Date(year, month - 1, day).getTime() / 1000;
};
const CompWait = () => {
    const Startdate = "2024-06-09";
    const StartTime = "11:40:00";
    // date to seconds since epoch
    const StartTimeEpoch = dateToSeconds(Startdate) + timeToSeconds(StartTime);
    const [wait, setWait] = useState(StartTimeEpoch - Math.floor(Date.now() / 1000));
    useEffect(() => {
        const interval = setInterval(() => {
            setWait(StartTimeEpoch - Math.floor(Date.now() / 1000));
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return wait;
}
export default CompWait;