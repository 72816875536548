import React from 'react'
import Header from '../Header'
import Drawer from '../Mobile/Drawer'
import useToggle from '../../Hooks/useToogle'
// import Header from '../Header'

export default function About({ user, setUser }) {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} user={user} setUser={setUser} />
            <div className='privacy'><h1>Terms And Conditions of our website.</h1>
    <p>These Terms & Conditions (“Terms”) of (a) use of our website www.qwackedu.com (“Website”), our applications
        (“Application”) or any products or services in connection with the Application/, Website/products (“Services”)
        is
        between QWACK EDUCATION (hereinafter referred to as “We/Us/Our”) and its users acting on their own or acting as
        a
        guardian on behalf of a minor (hereinafter referred to as “User/You/Your”).</p>
    <p>These Terms constitute an electronic record in accordance with the provisions of the Information Technology Act,
        2000
        and the Information Technology(Intermediaries guidelines) Rules, 2011 there under, as amended from time to time.
    </p>
    <p>Please read the Terms and the privacy policy of the Organization (“Privacy Policy” -
        https://www.qwackedu.com/privacy/)
        with respect to registration with Us, the use of the Application, Website, Services, and products carefully
        before using
        the Application, Website, Services or products. In the event of any discrepancy between the Terms and any other
        policies
        with respect to the Application or Website or Services or products, the provisions of the Terms shall prevail.
    </p>
    <p>Your use/access/browsing of the Application or Website or the Services or products or registration (with or
        without
        payment/with or without a subscription) through any means shall signify Your acceptance of the Terms and Your
        agreement
        to be legally bound by the same</p>
    <p>If You do not agree with the Terms or the Privacy Policy, please do not use the Application or Website or avail
        the
        Services or products. Any access to Our Services/Application/products through registrations/subscription is
        non-transferable</p>
    <h2>2. Services
    </h2>
    <p>The Website is an online platform where Users can gain access to materials that help them prepare for academic
        subjects
        required for K-12 classes. The User can register themselves online and then gain access to the worksheets,
        Grammar
        competition, Quiz Contest, Vocabulary Games. , etc. (“Content and Features”) after paying the money for the
        Content and
        Features.
        The content and features of our website shall be unlocked based on the subscription plan purchased by the User
        for such
        Content and Features.
    </p>
    <h2>3. Registration</h2>
    <p>To use the Services provided on the Website, it is mandatory to create an account. A User may create an account
        on the
        Website which shall collect only Your basic confidential information. To create an account, You need to choose a
        username and password. You also have the option of using Your social media accounts, such as Google account, to
        create
        Your Account. You must keep Your account and registration details current and correct for communications related
        to Your
        purchases from the Website.</p>
    <p>At the time of registration, the Organization shall collect the following personally identifiable information
        about You:
        Name - including first and last name, email address, mobile phone number and other contact details, demographic
        profile
        (like your age, gender, address, etc.,). You are solely responsible for protecting the confidentiality of Your
        username
        and password collectively called login credentials and any activity under the account will be deemed to have
        been done
        by You. In the event You provide Us with false/inaccurate details or the Organization has a reasonable ground to
        believe
        that false and inaccurate information has been furnished, We hold the right to permanently suspend Your account.
        It is
        Your sole responsibility to ensure that the account information provided by You is accurate, complete and
        latest.</p>
    <p>You shall be responsible for maintaining the confidentiality of the account information and for all activities
        that
        occur under Your account. You agree to (a) ensure that You successfully log out from Your account at the end of
        each
        session; and (b) immediately notify us of any unauthorized use of Your account. If there is reason to believe
        that there
        is likely to be a breach of security or misuse of Your account, We may request You to change the password or We
        may
        suspend Your account without any liability , for such period of time as We deem appropriate in the
        circumstances. We
        shall not be liable for any loss or damage arising from Your failure to comply with this provision.</p>
    <p>You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service
        providers and internet network availability and the Organization cannot guarantee accessibility to the
        Application at
        all times. In addition to the disclaimers set forth in the Terms, the Organization shall not be liable to You
        for any
        damages arising from Your inability to log into Your account and access the services of the Application at any
        time</p>
    <p>In case You want Us to contact You regarding the product or services, You are required to provide your contact
        information such as but not limited to, Full Name, Mobile Number, Email etc. By accepting these Terms, You are
        giving
        Your consent to receive a SMS/Call /mail from Us or Our representative with reference to this solicited inquiry
        even
        though you may be registered on the National Do Not Call Registry.</p>
    <h2>Eligibility</h2>
    <p>Persons who are “competent/capable” of contracting within the meaning of the Indian Contract Act,1872 shall be
        eligible
        to register for the Application and all Our products or Services. Persons who are minors, un-discharged
        insolvents etc.
        are not eligible to register for Our products or Services. As a minor, if You wish to use , such use shall be
        made
        available to You by Your legal guardian or parents, who has agreed to these Terms. In the event a minor utilizes
        the
        Application/Website/Services, it is assumed that he/she has obtained the consent of the legal guardian or
        parents and
        such use is made available by the legal guardian or parents. We will CONFIDENTIAL not be responsible for any
        consequence
        that arises as a result of misuse of any kind of Our Website or any of Our products or Services that may occur
        by virtue
        of any person including a minor registering for the Services/products provided. By using the products or
        Services You
        warrant that all the data provided by You is accurate and complete and that the student using the Application
        has
        obtained the consent of a parent/legal guardian (in case of minors). The Organization reserves the right to
        terminate
        Your subscription and / or refuse to provide You with access to the products or Services if it is discovered
        that You
        are under the age of 18 (eighteen) years and the consent to use the products or Services is not made by Your
        parent/legal guardian or any information provided by You is inaccurate. You acknowledge that the Organization
        does not
        have the responsibility to ensure that You conform to the aforesaid eligibility criteria. It shall be Your sole
        responsibility to ensure that You meet the required qualification. Any persons under the age of 18 (eighteen)
        should
        seek the consent of their parents/legal guardians before providing any Information about themselves or their
        parents and
        other family members on the Application.</p>
    <h2>5. Security</h2>
    <p>Transactions on the Website is secure and protected. Any information entered by the User when transacting on the
        Website
        is encrypted to protect the User against unintentional disclosure to third parties. The User’s credit and debit
        card or
        any payment related information is not received, stored by, or retained by the Organization / Website /
        Application in
        any manner. This information is supplied by the User directly to the relevant payment gateway, which is
        authorized to
        handle the information provided and is compliant with the regulations and requirements of various banks and
        institutions
        and payment franchisees that it is associated with.</p>
    <h2>License Access and User Obligations</h2>
    <p>Your use of Our products, Website, and Services is solely for Your personal and non- commercial use. Any use of
        the,
        Website, Services or products or their contents other than for personal purposes is prohibited. Your personal
        and
        non-commercial use of this Website, products and/or Our Services shall be subjected to the following
        restrictions:</p>
    <p>i. You may not decompile, reverse engineer, or disassemble the contents of the Application and / or Our Website
        and/or
        Services/ products or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create
        derivative works from, transfer, or sell any information or software obtained from the Application and / or Our
        Website
        and/or Services/products, or remove any copyright, trademark registration, or other proprietary notices from the
        contents of the Application and / or and / or Our Website and/or Services/products.
        ii. You will not (a) use this Our Website and/or any of Our product/s or Service/s for commercial purposes of
        any kind,
        or (b) advertise or sell the or any products, Services or domain names or otherwise (whether or not for profit),
        or
        solicit others (including, without limitation, solicitations for contributions or donations) or use any public
        forum for
        commercial purposes of any kind, or (c) use the Website/Our products and Services in any way that is unlawful,
        or harms
        the Organization or any other person or entity as determined by the Organization.
        iii. No User shall be permitted to perform any of the following prohibited activities while availing Our
        Services:
        a. Making available any content that is misleading, unlawful, harmful, threatening, abusive,
        tortious,defamatory,
        libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another’s privacy,
        hateful, or
        racially, ethnically or otherwise objectionable;
        b. Stalking, intimidating and/or harassing another and/or inciting other to commit violence;
        c. Transmitting material that encourages anyone to commit a criminal offense, that results in civil liability or
        otherwise breaches any relevant laws, regulations or code of practice;
        d. Interfering with any other person’s use or enjoyment of the Website/Services;
        e. Making, transmitting or storing electronic copies of materials protected by copyright without the permission
        of the
        owner, committing any act that amounts to the infringement of intellectual property or making available any
        material
        that infringes any intellectual property rights or other proprietary rights of anyone else;
        f. Make available any content or material that You do not have a right to make available under any law or
        contractual or
        fiduciary relationship unless You own or control the rights thereto or have received all necessary consents for
        such use
        of the content;
        g. Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person
        or
        entity;
        h. Post, transmit or make available any material that contains viruses, trojan horses, worms, spyware,time
        bombs,
        cancelbots, or other computer programming routines, code, files or such other programs that may harm the
        Application/services, interests or rights of other users or limit the functionality of any computer software,
        hardware
        or telecommunications, or that may harvest or collect any data or personal information about other Users without
        their
        consent;
        i. Access or use the Application/Website/Services/products in any manner that could damage, disable, overburden
        or
        impair any of the Application’s/Website’s servers or the networks connected to any of the servers on which the
        Application/Website is hosted;
        j. Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws
        related to the
        access to or use of the Application/Website/Services/products, violate any requirements, procedures, policies or
        regulations of networks connected to the Application/Website/Services/products, or engage in any activity
        prohibited by
        these Terms;
        k. Disrupt or interfere with the security of, or otherwise cause harm to, the
        Application/Website/Services/products,
        materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks
        connected to
        or accessible through the Application/Website/Services/products or any affiliated or linked sites;
        l. Interfere with, or inhibit any user from using and enjoying access to the Application/Website/
        Services/products, or
        other affiliated sites, or engage in disruptive attacks such as denial of service attack on the
        Application/Website/Services/products;
        m. Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or
        any
        similar or equivalent manual process, to increase traffic to the Application/Website/Services/products, to
        access,
        acquire, copy or monitor any portion of the Application /Website/Services/products, or in any way reproduce or
        circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt
        to obtain
        any content, documents or information through any means not specifically made available through the Application/
        Website/Services/products;
        n. Alter or modify any part of the Services;
        o. Use the Services for purposes that are not permitted by: (i) these Terms; and (ii) any applicable
        law,regulation or
        generally accepted practices or guidelines in the relevant jurisdiction; or
        p. Violate any of the terms specified under the Terms for the use of the Application/Website/Services/products.
        iv. By submitting content on or through the Services (Your “Material”), You grant us a worldwide, non-exclusive,
        royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt,modify, publish,
        transmit,
        display and distribute such Material in any and all media or distribution methods (now known or later developed)
        and to
        associate Your Material with You, except as described below. You agree that others may use Your Material in the
        same way
        as any other content available through the Services. Other users of the Services may fork, tweak and repurpose
        Your
        Material in accordance with these Terms. If You delete Your user account, Your Material and name may remain
        available
        through the Services
        v. We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or
        other
        government officials, as We, in Our sole discretion, believe it necessary or appropriate in connection with the
        investigation and/or resolution of possible crimes, especially those that may involve personal injury. In order
        to
        protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages
        or
        emails which a user may send to otherUsers in any 24-hour period which We deem appropriate in our sole
        discretion. You
        understand that we have the right at all times to disclose any information (including the identity of the
        persons
        providing information or materials on the Website/Application) as necessary to satisfy any law, regulation or
        valid
        governmental request. This may include, without limitation, disclosure of the information in connection with an
        investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court
        order or
        subpoena.
        vi. We have no obligation to monitor the Materials posted on the Website by You. We shall have the right to
        remove or
        edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either
        the spirit
        or letter of these Terms. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE
        MATERIALS YOU
        POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES. In no event shall We assume or have any responsibility or
        liability
        for any Content posted or for any claims, damages or losses resulting from the use of Content and/or appearance
        of
        Content on the Website. You hereby represent and warrant that You have all necessary rights in and to all
        Content which
        You provide and all information it contains and that such Content infringe any proprietary or other rights of
        third
        parties or contain any libelous, tortuous, or otherwise unlawful information.
    </p>
    <h2>Communications
    </h2>
    <p>The Organization may, based on any form of access to the website (including free download/trials) or Services or
        Website
        or registrations through any source whatsoever, contact the User through SMS, email, WhatsApp and call, to give
        information about its products as well as notifications on various CONFIDENTIAL important updates and/or to seek
        permission for demonstration of its products. The User expressly grants such permission to contact him/her
        through
        telephone, SMS, e-mail, WhatsApp and holds the Organization indemnified against any liabilities including
        financial
        penalties, damages, expenses in case the User’s mobile number is registered with Do not Call (DNC) database. By
        registering yourself, You agree to make Your contact details available to Our employees, associates and partners
        so that
        You may be contacted for education information and promotions through telephone, SMS, email, WhatsApp etc. You
        can opt
        out from such communication and/or newsletters either by unsubscribing on the Website itself, or by contacting
        the
        customer services team and placing a request for unsubscribing by sending an email to unsubscribe@qwackedu.com
    </p>
    <p>You may also be contacted by Service Providers with whom We have entered into a contract in furtherance of Our
        rights,
        duties and obligations under this document and all other policies followed by Us. Such contact will be made only
        in
        pursuance of such objectives, and no other calls will be made.

        The sharing of the information provided by You will be governed by the Privacy Policy and We will not give out
        such
        contact information of yours to third parties not connected with the Website.
    </p>
    <h2>8. Payment
    </h2>
    <h2>The following payment options are available on the Website:</h2>
    <ol>
        <li>Domestic credit cards issued by banks and financial institutions that are part of the Visa and Master Card
            networks;</li>
        <li>Rupay, Visa & Master Card Debit cards;</li>
        <li>Netbanking/Direct Debit payments from select banks in India. A list of available options will be made
            available at the
            time of purchase.</li>
        <li>Payment Wallets</li>
        <li>UPI </li>
    </ol>
    <p>As prescribed by the financial institutions issuing the credit or debit cards affiliated with Visa / Master Card
        /
        Amex/RuPay, the User will be required to submit his/her 16-digit card number, card expiry date CVV number
        (usually on
        the reverse of the card) while making an online transaction. The User must also have enrolled his/her card with
        VBV
        (Verified by Visa), MSC (MasterCard Secure Code) or any other applicable provider in order to complete the
        transaction.
        The User is hereby expressly made aware that his/her card statements will reflect that a payment has been made
        in favour
        of the Organization.</p>
    <p>The User is further aware that in case of third-party statements, including bank and credit card statements, the
        merchant name may appear in an abbreviated format, and the Organization has no control over the same. To
        successfully
        subscribe on the Website, the User is required to complete the transaction by making the payment for the
        services opted
        for. User will be charged a non-refundable enrolment fee along with the package subscription fee. This is a
        one-time fee
        for providing access to the ClassEdge platform and for guidance on using the platform (e.g., activation help,
        setting up
        student's syllabus), counselling service if availed, cost for user verification and cost of payment gateway
        charges.</p>
    <h2>Intellectual Property Rights</h2>
    <p>Except as specifically mentioned in these Terms, all information, content, material, trademarks, services marks,
        trade
        names, and trade secrets including but not limited to the software, text, logos, images, graphics, video, script
        and
        audio, contained in the Application, Website, Services and products CONFIDENTIAL are proprietary property of the
        organization (“Proprietary Information”). No Proprietary Information may be copied, downloaded, recorded,
        reproduced,
        modified, republished, uploaded, posted, transmitted or distributed in any way without obtaining prior written
        permission from the Organization and nothing on this Application or Website or Services shall be or products
        deemed to
        confer a license of or any other right, interest or title to or in any of the intellectual property rights
        belonging to
        the Organization, to the User. You may own the medium on which the information, content or materials resides,
        but the
        Organization shall at all times retain full and complete title to the information, content or materials and all
        intellectual property rights inserted by the Organization on such medium. Certain contents on the Website may
        belong to
        third parties. Such contents have been reproduced after taking prior consent from said party and all rights
        relating to
        such content will remain with such third party. Further, You recognize and acknowledge that the ownership of all
        trademarks, copyright, logos, service marks and other intellectual property owned by any third party shall
        continue to
        vest with such party and You are not permitted to use the same without the consent of the respective third
        party. The
        Organization is not responsible for the content of any third-party sites and does not make any representations
        regarding
        the content or accuracy of material on such sites. If You decide to access a link of any third- party websites,
        You do
        so entirely at Your own risk and expense.</p>
    <h2>9. Refund, Cancellation & Expiration Policy</h2>
    <p>User can cancel the subscription by e-mailing us on refund@qwackedu.com mentioned on our website
        www.qwackedu.com.
        Cancellation of subscribed services will be as per the billing plan selected by the subscriber during the time
        of sign
        up. Cancellation of monthly subscription is not permitted without sufficient cause. Cancellation is only allowed
        for
        subscriptions that are identified as refundable under the applicable billing plan opted by the subscriber and it
        will be
        sole discretion of the organization to refund the amount. The cancellation request will be processed after 10
        days from
        receipt of cancellation request. The cancellation will be affected from the end of monthly billing cycle of the
        refundable subscription. The refund amount will be calculated based on monthly subscription rate for the time
        the
        subscription was active. After deducting the amount of the active period based on monthly subscription rate, the
        balance
        amount will be refunded. The refund amount if any will be credited to same account/instrument used for purchase
        of the
        subscription. Cancellation will not be allowed after 30 days.</p>
    <h2>10. Privacy</h2>
    <p>We encourage You to read the Privacy Policy on Our Website and to use the information it contains to make
        informed
        decisions regarding Your personal information. Please also note that certain information, statements, data and
        content
        (such as but not limited to photographs) which You provide on the Website are likely to reveal Your gender,
        ethnic
        origin, nationality, age, and/or other personal information about You. You acknowledge and agree that Your
        submission of
        such information is voluntary on Your part. Further, You acknowledge, consent and agree that We may access,
        preserve,
        and disclose information You provide to Us at any stage during Your use of the Website/Application/Services.
        Disclosures
        of information to Third Parties are further addressed in Our Privacy Policy.</p>
    <h2>11. Governing Law and Jurisdiction</h2>
    <p>The Terms shall be governed by and construed and enforced in accordance with laws of India and shall be binding
        upon the
        parties hereto in India and worldwide. The appropriate courts at Ahmedabad, India shall have exclusive
        jurisdiction to
        adjudicate any dispute arising out of these Terms</p>
    <h2>General Provisions</h2>
    <p>(i) In the event of Your breach of these Terms, You agree that the organization will be irreparably harmed and
        may not
        have an adequate remedy in money or damages. The Organization, therefore, shall be entitled in such event to
        obtain an
        injunction against such a breach from any court of competent jurisdiction. The Organization’s right to obtain
        such
        relief shall not limit its right to obtain other remedies.
        (ii) All notices served by the Organization shall be provided via email to Your account or as a general
        notification on
        the Application. Any notice to be provided to the Organization should be sent to legal@qwackedu.com.
        (iii) The Terms, along with the Privacy Policy, and any other guidelines made applicable to the Application from
        time to
        time, constitute the entire agreement between the Organization and You with respect to Your access to or use of
        the
        Application, Website and the Services thereof.
        (iv) You cannot assign or otherwise transfer Your obligations under the Terms, or any right granted hereunder to
        any
        third party. The Organization’s rights and obligations under the Terms are freely transferable by the
        Organization to
        any third parties without the requirement of seeking Your consent.
        (v) If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof,
        to be
        unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the
        intent of
        the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and
        effect.
        (vi) Any failure by the Organization to enforce or exercise any provision of the Terms, or any related right,
        shall not
        constitute a waiver by the Organization of that provision or right.
        (vii) You acknowledge that Your participation on the Application, does not make You an employee or agency or
        partnership
        or joint venture or franchise of the Organization.
        (viii) The Organization provides these Terms so that You are aware of the terms that apply to your use of the
        Website/Application and Services. You acknowledge that, the Organization has given You a reasonable opportunity
        to
        review these Terms and that You have agreed to them.
        (ix) Any violation by You of these Terms may result in immediate suspension or termination of Your Accounts
        apart from
        any legal remedy that the Organization can avail. In such instances, the Organization may also disclose Your
        Account
        Information if required by any Governmental or legal authority. You understand that the violation of these Terms
        could
        also result in civil or criminal liability under applicable laws.
        (x) If You breach any provision of these Terms or any other agreements or policies referred to herein and We
        take no
        action, We will still be entitled to use Our rights and remedies in any other situation where You breach these
        Terms or
        any other such agreement or policy.
        (xi) Your obligations and Our rights and remedies set out in these Terms are cumulative and are in addition to
        Your
        obligations and Our rights and remedies at law or in equity.
    </p>
    <h2>Contact US</h2>
    <p>If You have concerns relating to the Website or these Terms, please contact Us:</p>
    <p>a. For Website related concern: info@qwackedu.com
    b. For Terms related concern: Legal@qwackedu.com
</p></div>
        </>
    )
}
