import React, { useState } from 'react'
import './Otp.scss'
import { Link , useNavigate } from 'react-router-dom'
export default function Auth() {
    const navigate = useNavigate();
    // form validation
    const [form, setForm] = useState({
        email: '123',
        password: '123',
    })
    console.log(form.email, form.password);
    const [isChecked, setIsChecked] = useState(true)
    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImlhdCI6MTY2NDA0NTE3Mn0.81TpUG4HUR0sYUyF4mQ8wf3VhKD1MeFgNQRi1_Z6_cc
    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsInJvbGUiOiJ1c2VyIiwiaWF0IjoxNjY0MDQ0OTA3fQ.Riu9-ZdVpabnhgMlqJImECPwkW6zVEJED1zH8TiDdL8
    return (
        <>
            <div className="signup">
                <div className="signup__container">
                    {/* <div className="signup__container__header">
                        <h1></h1>
                    </div> */}
                    <div className="signup__container__form">
                        {/* <input type="text" name="Your Name" id="name1" placeholder='Enter your Name' />
                        <input type="text" name="Your Name" id="name1" placeholder="Enter your child's Name" />
                        <label htmlFor="Your Phone">+91</label>
                        <input type="text" name="Your Phone" id="phone1" placeholder='Enter your Phone' />
                        <button>Get OTP on Whatsapp <i className='fa fa-whatsapp'></i></button>
                        <input type="text" name="Your Password" id="password1" placeholder='Enter your Password' />
                        <input type="text" name="Confirm Password" id="password2" placeholder='Confirm your Password' />
                        <input type="checkbox" name="Privacy" id="privacy" checked={isChecked} onClick={() => {setIsChecked(!isChecked)}}/>
                        <label htmlFor="Privacy" onClick={() => {setIsChecked(!isChecked)}}>I agree to the <Link to="/privacy">Privacy Policy</Link> and <Link to="/T&C">Terms of Use</Link></label>
                        <button onClick={() => navigate('/otp')}>Sign Up</button>
                        Already a member Log in <a href="#">here</a> */}
                        {/* form for otp */}
                        <input type="text" name='Otp' placeholder='Enter Otp'/>
                        <button onClick={() => navigate('/#verified')}>Verify</button>
                        <div className="resend" >Didn't recive OTP ? resend</div>
                    </div>
                </div>
            </div>
        </>
    )
}
