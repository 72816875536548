import React from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import Snowfall from 'react-snowfall';
import useToggle from '../../Hooks/useToogle';
import Drawer from '../Mobile/Drawer';
import Header from '../Header';
import './competition.scss';
import Footer from '../Footer';
import IconTwo from '../../assets/images/icon/2.png';
import { useState } from 'react';
import { socket } from '../../config/web-sockets'
// import useWindowDimensions from '../../Hooks/useWindowDimensions';


export default function Competition({
    user
}) {
    // const { height, width } = useWindowDimensions();
    const [drawer, drawerAction] = useToggle(false);
    const [display, setDisplay] = useState(true);
    const [username, setUsername] = useState('');
    const [room, setRoom] = useState('');
    const [error, setError] = useState('');
    const onUsernameChange = (e) => {
        const inputValue = e.target.value;
        setUsername(inputValue);

    }
    const onRoomChange = (e) => {
        const roomNo = e.target.value;
        setRoom(roomNo);
    }
    const onClick = () => {
        if (username && room) {

            socket.emit('join', { username, room }, (error) => {
                if (error) {
                    setError(error)
                    alert(error);
                } else {
                    console.log('congratulations');
                }
            });
        }
    }
    // socket.on('welcome', (data) => {
    //     props.onJoinSuccess(data);
    // });

    return (
        <>
            <div class="compbackground">
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <div class="compcube"></div>
                <Drawer drawer={drawer} action={drawerAction.toggle} />
                <Header action={drawerAction.toggle} user={user} />
                <div className='parent'>
                    <div className='heading'>
                        <div>
                            <img className='trophy' src={IconTwo} alt="" />
                            <h1>Grammar Quizzes </h1>
                            <img className='trophy' src={IconTwo} alt="" />
                        </div>
                        {/* <p>
                        Students can Host and invite Friends to Play Grammar quiz by selecting any Grammar Topics.
                    </p> */}
                    </div>
                    <Link to={`/quiz/topics?src=single`}>
                        <div className='practice'>
                            <h2>Single Player</h2>
                            <p>(Practice Mode)</p>
                        </div>
                    </Link>
                    <div className='practice' onClick={() => {
                        // setDisplay(!display)
                    }}
                    >
                        <h2>Play With Friends</h2>
                        <p>(Coming Soon) <i className='fa fa-info-circle' title='TODO: write instructions'></i> </p>
                    </div>
                    {/* <div className="card-container" style={display ? { opacity: 0 } : {}}>
                        <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="main">
                                        <input type='text' className='input' placeholder='Enter your name' />
                                        <Link to={`/quiz/topics?code=${Math.floor(Math.random() * 10000)}`}>
                                            <button className='comp-button active'><span>Create</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <h2>Create a Room</h2>
                                <p>Students can Host and invite Friends to Play Grammar quiz by selecting any Grammar Topics.</p>
                            </div>
                        </div>

                        <div class="card">
                            <div class="face face1">
                                <div class="content">
                                    <div class="main">
                                        <input type='text' className='input' placeholder='Enter your name' onChange={onUsernameChange} />
                                        <input type='number' className='input' placeholder='Enter a code' onChange={onRoomChange} />
                                        <Link to={`/quiz/waiting/`}>
                                        <button className='comp-button active' onClick={onClick}><span>Join</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="face face2">
                                <h2>Join a Room</h2>
                                <p> Enter a code given by your friend.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer />

        </>
    )
}
