import React from 'react'
// import { Link } from 'react-router-dom'
import Drawer from '../Mobile/Drawer';
import useToggle from '../../Hooks/useToogle';
import Header from '../Header';
import BackToTop from '../Helper/BackToTop';
import Footer from '../Footer';
import qwack from '../../assets/images/mickey-9406.png';
import qa from '../../assets/images/icon/1a.jpg';
import qb from '../../assets/images/icon/2b.jpg';
import qc from '../../assets/images/icon/3c.jpg';
import qd from '../../assets/images/icon/4d.jpg';
import './about.scss'
import Loader from '../Loading';
import useApi from '../../Hooks/useApi';

export default function Quiz({ user }) {
  const [drawer, drawerAction] = useToggle(false);
  // get data from server
  const link = `${process.env.REACT_APP_SERVER}/api/about?populate=*`
  const { loading, data } = useApi(link);
  const about = loading ? [] : data.data;
  const { attributes } = about;
  console.log(about);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Drawer drawer={drawer} action={drawerAction.toggle} />
          <Header action={drawerAction.toggle} user={user} />
          <div className="about">
            <div className="container">
              <div className="content">
                <div>
                  <h2 className='main-head'>About us</h2>
                  <p>{attributes.Para1}</p>
                  <p>{attributes.Para2}</p>
                </div>
              </div>
              <div className="image">
                <img src={qwack} alt="placeholder" style={{ webkitBoxShadow: "none", boxShadow: "none", MozBoxShadow: 'none', rotate: "0deg" }} />

              </div>
            </div>

            <div className="container">
              <div className="content">
                <div>
                  <h2>{attributes.Feature1.Heading}</h2>
                  <p> {attributes.Feature1.Para1}</p>
                  <p> {attributes.Feature1.Para2}</p>
                </div>
              </div>
              <div className="image">
                <img src={qa} alt="placeholder" />
              </div>
            </div>
            <div className="container">
              <div className="content">
                <h2>{attributes.Feature2.Heading}</h2>
                <p> {attributes.Feature2.Para1}</p>
                <p> {attributes.Feature2.Para2}</p>
              </div>
              <div className="image">
                <img src={qb} alt="placeholder" />
              </div></div>
            <div className="container">
              <div className="content">
                {/* <h2>Our Mission Vision and Values</h2> */}
                <h2>{attributes.Feature3.Heading}</h2>
                <p> {attributes.Feature3.Para1}</p>
                <p> {attributes.Feature3.Para2}</p>
              </div>
              <div className="image">
                <img src={qc} alt="placeholder" />
              </div></div>
            <div className="container">
              <div className="content">
                {/* <h2>Our Mission Vision and Values</h2> */}
                <h2>{attributes.Feature4.Heading}</h2>
                <p> {attributes.Feature4.Para1}</p>
                <p> {attributes.Feature4.Para2}</p>
              </div>
              <div className="image">
                <img src={qd} alt="placeholder" />
              </div></div>
          </div>
          <Footer />
          <BackToTop />
        </>
      )
      }
    </>
  )
}
