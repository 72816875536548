import React, { useEffect } from 'react';
import TopToBottom from '../../lib/TopToBottom';
import whatsapp from '../../assets/images/WhatsApp.svg';

function BackToTop({ className }) {
    useEffect(() => {
        TopToBottom('.back-to-top');
    });
    return (
        <>
            <div className={`back-to-top ${className || ''}`}>
                <a href="https://wa.me/919327804413">
                    <img src={whatsapp} alt="whatsApp" />
                </a>
            </div>
        </>
    );
}

export default BackToTop;